import rating from '../../images/star.jpg'
import captera from '../../images/capteraimg.svg'
import gate from '../../images/1.png';
import smsEmail from '../../images/2-.png'
import gateWays from '../../images/3.png'
import software from '../../images/4.png'
import { LazyLoadImage } from 'react-lazy-load-image-component'
export default function Content() {

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    };


    return (
        <>
            <div className="container-fluid bg-light pt-0">
                <div className="container py-3 ">
                    <div className="row my-4">
                        <div className="col-lg-4 px-1 mb-3">
                            <div className="card br-20 h-100 text-center background-grey">
                                <div className="card-body px-3 py-4">
                                    <div className="py-4">
                                        <span className="border px-3 py-4  border-dark rounded-pill">
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="40"
                                                height="40"
                                                viewBox="0 0 24 24">
                                                <path fill="#2d5cd0" d="M16 13h-2a2 2 0 11-4 0H8a4 4 0 008 0zM10 10a1 1 0 11-2 0 1 1 0 012 0zM15 11a1 1 0 100-2 1 1 0 000 2z"></path>
                                                <path
                                                    fillRule="evenodd" fill="#2d5cd0"
                                                    d="M22 12c0 5.523-4.477 10-10 10S2 17.523 2 12 6.477 2 12 2s10 4.477 10 10zm-2 0a8 8 0 11-16 0 8 8 0 0116 0z"
                                                    clipRule="evenodd"
                                                ></path>
                                            </svg>
                                        </span>
                                    </div>
                                    <p className="fw-bold mt-2 mb-4">Effizient</p>
                                    <p className="">Vereinfachen Sie den Einzugsvorgang für Ihre Mieter mit nur 6 Klicks im Mieterportal.</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 px-1 mb-3">
                            <div className="card br-20 h-100 text-center text-white bg-blue">
                                <div className="card-body px-3 py-4">
                                    <div className="py-4">
                                        <span className="border px-3 py-4  border-white rounded-pill">
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="40"
                                                height="40"
                                                viewBox="0 0 512 512">
                                                <path fill="#ffff" d="M396.8 352h22.4c6.4 0 12.8-6.4 12.8-12.8V108.8c0-6.4-6.4-12.8-12.8-12.8h-22.4c-6.4 0-12.8 6.4-12.8 12.8v230.4c0 6.4 6.4 12.8 12.8 12.8zm-192 0h22.4c6.4 0 12.8-6.4 12.8-12.8V140.8c0-6.4-6.4-12.8-12.8-12.8h-22.4c-6.4 0-12.8 6.4-12.8 12.8v198.4c0 6.4 6.4 12.8 12.8 12.8zm96 0h22.4c6.4 0 12.8-6.4 12.8-12.8V204.8c0-6.4-6.4-12.8-12.8-12.8h-22.4c-6.4 0-12.8 6.4-12.8 12.8v134.4c0 6.4 6.4 12.8 12.8 12.8zM496 400H48V80c0-8.84-7.16-16-16-16H16C7.16 64 0 71.16 0 80v336c0 17.67 14.33 32 32 32h464c8.84 0 16-7.16 16-16v-16c0-8.84-7.16-16-16-16zm-387.2-48h22.4c6.4 0 12.8-6.4 12.8-12.8v-70.4c0-6.4-6.4-12.8-12.8-12.8h-22.4c-6.4 0-12.8 6.4-12.8 12.8v70.4c0 6.4 6.4 12.8 12.8 12.8z"></path>
                                            </svg>
                                        </span>
                                    </div>
                                    <p className="fw-bold mt-2 mb-4">Zentralisiert</p>
                                    <p className="">Sehen Sie den Status aller Einheiten in Ihrer Anlage auf einen Blick und führen Sie Vermietungsvorgänge durch.</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 px-1 mb-3">
                            <div className="card br-20 h-100 text-center background-grey">
                                <div className="card-body px-3 py-4">
                                    <div className="py-4">
                                        <span className="border px-3 py-4  border-dark rounded-pill">
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="40"
                                                height="40"
                                                viewBox="0 0 24 24"
                                            >
                                                <path fill="#2d5cd0"
                                                    fillRule="evenodd"
                                                    d="M9 5H5v4h4V5zM3 3v8h8V3H3zM19 5h-4v4h4V5zm-6-2v8h8V3h-8zM9 15H5v4h4v-4zm-6-2v8h8v-8H3z"
                                                    clipRule="evenodd"
                                                ></path>
                                                <path fill="#2d5cd0" d="M13 13h2v8h-2v-8zM16 13h2v8h-2v-8zM19 13h2v8h-2v-8z"></path>
                                            </svg>
                                        </span>
                                    </div>
                                    <p className="fw-bold mt-2 mb-4">Leistungsstark</p>
                                    <p className="">Nutzen Sie leistungsstarke Integrationen für Buchhaltung, Online-Zahlungen und Zugangssysteme.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container mt-4">
                <h1 className="text-center fw-bold">Optimiertes Self-Storage-Management</h1>
                <h6 className="text-center fs-5 my-3">Mit 6Storage vermieten Sie Einheiten online, verwalten Mietaktivitäten und sorgen für pünktliche Zahlungen.</h6>
                <div className="row my-5">
                    <div className="col-lg-6 my-3">
                        <div className="d-flex">
                            <div>
                                <span className="rounded-pill p-2 bg-blue">
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="25"
                                        height="22"
                                        viewBox="0 0 512 512"
                                    >
                                        <path fill="#fff" d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z"></path>
                                    </svg>
                                </span></div> <p className="fw-bold d-inline ms-3">Rechnungs- und Zahlungsautomatisierung</p>
                        </div>
                        <p className=" my-4">
                            Werden Sie bei automatischen Rechnungen benachrichtigt und verwalten Sie mehrere Kreditkarten für automatische Zahlungen.
                        </p>
                    </div>
                    <div className="col-lg-6 my-3">
                        <div className="d-flex">
                            <div>
                                <span className="rounded-pill p-2 bg-blue">
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="25"
                                        height="22"
                                        viewBox="0 0 512 512"
                                    >
                                        <path fill="#fff" d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z"></path>
                                    </svg>
                                </span></div> <p className="fw-bold d-inline ms-3">Anlagenmanagement für mehrere Einrichtungen</p>
                        </div>
                        <p className=" my-4">
                            Zentralisieren Sie alle Standortdetails in einem Konto und vergessen Sie regionales Management.
                        </p>
                    </div>
                    <div className="col-lg-6 my-3">
                        <div className="d-flex">
                            <div>
                                <span className="rounded-pill p-2 bg-blue">
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="25"
                                        height="22"
                                        viewBox="0 0 512 512"
                                    >
                                        <path fill="#fff" d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z"></path>
                                    </svg>
                                </span></div> <p className="fw-bold d-inline ms-3">Lead-Management und Reservierung</p>
                        </div>
                        <p className=" my-4">
                            Verfolgen Sie, woher Ihre Leads kommen, sehen Sie Analysen der Lead-Daten und fassen Sie zeitnah nach.
                        </p>
                    </div>
                    <div className="col-lg-6 my-3">
                        <div className="d-flex">
                            <div>
                                <span className="rounded-pill p-2 bg-blue">
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="25"
                                        height="22"
                                        viewBox="0 0 512 512"
                                    >
                                        <path fill="#fff" d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z"></path>
                                    </svg>
                                </span></div> <p className="fw-bold d-inline ms-3">Interaktives und intuitives Dashboard</p>
                        </div>
                        <p className=" my-4">
                            Sehen Sie den Status Ihrer Anlage auf einem Dashboard – geleistete Zahlungen, überfällige Mieten und mehr!
                        </p>
                    </div>
                    <div className="col-lg-6 my-3">
                        <div className="d-flex">
                            <div>
                                <span className="rounded-pill p-2 bg-blue">
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="25"
                                        height="22"
                                        viewBox="0 0 512 512"
                                    >
                                        <path fill="#fff" d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z"></path>
                                    </svg>
                                </span></div> <p className="fw-bold d-inline ms-3">Anpassung an die Muttersprache</p>
                        </div>
                        <p className=" my-4">
                            ellen Sie 6Storage auf Ihre Muttersprache ein, um Ihren Mietern ein optimales Erlebnis zu bieten.
                        </p>
                    </div>
                    <div className="col-lg-6 my-3">
                        <div className="d-flex">
                            <div>
                                <span className="rounded-pill p-2 bg-blue">
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="25"
                                        height="22"
                                        viewBox="0 0 512 512"
                                    >
                                        <path fill="#fff" d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z"></path>
                                    </svg>
                                </span></div> <p className="fw-bold d-inline ms-3">Einzugs-/Auszugs-Assistent</p>
                        </div>
                        <p className=" my-4">
                            Prüfen Sie den Status aller Anlageneinheiten und führen Sie Vermietungen mit einem Klick durch.
                        </p>
                    </div>
                </div>
            </div>
            <div className="container mt-4">
                <h2 className="text-center fw-bold mb-4">Warum 6Storage?</h2>
                <div className="row  border-violet br-20 px-1 pt-3 px-lg-4 pt-lg-5 ">
                    <div className="col-lg-6 text-center px-3">
                        <p className="lh-lg text-dark font-size-0.9rem">
                            „Tolle Software und tolles Support-System! Bei der Eröffnung der Anlage haben wir zunächst ein anderes System verwendet. Als wir dann die 6Storage-Software auf Google entdeckten, war das ein Unterschied wie Tag und Nacht. Alle Vorschläge aus Support-Tickets werden in die Software eingearbeitet und machen sie zu einem individuellen Erlebnis. Dem Support-Team ist nichts zu viel Mühe.
                            <b className="">Bin sehr zufrieden mit dieser Software. Kann sie anderen in der Self-Storage-Branche nur empfehlen.</b>
                        </p>
                        <div className="">
                            <LazyLoadImage effect="blur" className="w-25 h-25" src={rating} />
                        </div>
                        <h5 className="fw-bold mt-3">Gail B.</h5>
                        <h5 className="fw-bold">Unternehmensverwaltung</h5>
                        <div className="my-4">
                            <LazyLoadImage effect="blur" src={captera} className="w-25 h-25" />
                        </div>
                    </div>
                    <div className="col-lg-6 text-center px-3">
                        <p className="lh-lg text-dark font-size-0.9rem"><b>„Dank 6Storage habe ich jetzt einen aktuellen Überblick über alle Vorgänge in der Anlage und wo sich all meine Mieter befinden. Es ist einfach, Zahlungen zu verwalten und den Schuldenstand zu verfolgen.</b>
                            Die automatische Fakturierung und Anwendung von Verzugszinsen sind die Highlights. Kunden per E-Mail/SMS aus der Software heraus zu benachrichtigen, macht das Schuldenmanagement effizienter. Die Lösung hat alle Prozesse im Unternehmen rationalisiert. Mir gefällt, dass es eine Cloud-Lösung ist, die mir von überall Zugriff gewährt.”</p>
                        <div className="">
                            <LazyLoadImage effect="blur" className="w-25 h-25" src={rating} />
                        </div>
                        <h5 className="fw-bold mt-3">Chris O.</h5>
                        <h5 className="fw-bold">Anlagenmanagement</h5>
                        <div className="my-4">
                            <LazyLoadImage effect="blur" src={captera} className="w-25 h-25" />
                        </div>
                    </div>
                </div>
            </div>
            <div className="container mt-4">
                <h2 className="text-center fw-bold mb-4">Unsere Premium-Integrationen</h2>
                <div className="row mt-1">
                    <div className="col-lg-3 mb-4 px-1">
                        <div className="card p-3 br-20 h-100  bg-blue-dark">
                            <LazyLoadImage effect="blur" src={gate} className="card-img-top h-100 w-100" />
                            <div className="card-body px-0 py-4">
                                <h5 className=" text-white">Gate-Zugang</h5>

                            </div>
                        </div>
                    </div>
                    <div className="col-lg-3 mb-4 px-1">
                        <div className="card p-3 br-20 h-100  background-grey">
                            <LazyLoadImage effect="blur" src={smsEmail} className="card-img-top h-100 w-100" />
                            <div className="card-body px-0 py-4">
                                <h5 className=" text-dark">SMS & E-Mail</h5>

                            </div>
                        </div>
                    </div>
                    <div className="col-lg-3 mb-4 px-1">
                        <div className="card p-3 br-20 h-100  bg-blue-dark">
                            <LazyLoadImage effect="blur" src={gateWays} className="card-img-top h-100 w-100" />
                            <div className="card-body px-0 py-4">
                                <h5 className=" text-white">Zahlungs-Gateways</h5>

                            </div>
                        </div>
                    </div>
                    <div className="col-lg-3 mb-4 px-1">
                        <div className="card p-3 br-20 h-100  background-grey">
                            <LazyLoadImage effect="blur" src={software} className="card-img-top h-100 w-100" />
                            <div className="card-body px-0 py-4">
                                <h5 className=" text-dark">Buchhaltungssoftware</h5>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container">
                <h2 className="text-center px-2 fw-bold mt-5 mb-3">Der clevere Weg, eine Storage-Anlage zu betreiben und Kunden zu binden</h2>
                <h3 className="text-center px-2">Kuratiert von einem Self-Storage-Besitzer, genutzt von globalen Storage-Betreibern aus über 28 Ländern.</h3>
                <div className="my-4 text-center" >
                    <button onClick={scrollToTop} className="btn text-white px-4 br-20 " style={{ background: '#FA1378' }}>Kostenlose Demo</button>
                </div>
            </div>
        </>
    )
}