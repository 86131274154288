import { useEffect, useState } from "react";
import './login.css';
import { useAuthState } from "react-firebase-hooks/auth";
import { useNavigate } from "react-router-dom";
import { logInWithEmailAndPassword, auth, logout } from "../../config/firebase";
import companyLogo from "../../images/logo.png";

export default function Login() {
    const navigate = useNavigate();
    const [email, SetEmail] = useState('');
    const [password, SetPassword] = useState('')
    const [user, loading, error] = useAuthState(auth);

    useEffect(() => {
        if (loading) {
          return;
        }
        if (user){
            localStorage.setItem('check', 'true')
            navigate("/admin/home");
        } 
      }, [user, loading]);


    return (
        <>
            <div className="style">
            <div className="form-outline rounded-3">
            <div class="form">  
            <img className="w-25 mx-auto d-block" src={companyLogo}/>  
            <br /> 
            <h2>Login Page</h2>
            <div className="inputBox w-100 mt-4">
                <input type="text" required value={email} onChange={e => SetEmail(e.target.value) }/>
                <span>Username</span>
                <i></i>
            </div>
            <div className="inputBox w-100 mt-4">
                <input type="Password" required value={password} onChange={e => SetPassword(e.target.value)}/>
                <span>Password</span>
                <i></i>
            </div>
            <br />
                <button className="btn-default rounded-pill py-2 px-4 mt-1" onClick={() => logInWithEmailAndPassword(email, password)}>Login</button>
            </div>
            </div>
            </div>
        </>
    )

}