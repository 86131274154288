import rating from '../../../images/star.jpg'
import captera from '../../../images/capteraimg.svg'
import g2 from '../../../images/g2.png'
import getapp from '../../../images/getapp.png'
import sourceforce from '../../../images/sourceforce.png'
import trustpilot from '../../../images/trustpilot.png'
import trustpilotstar from '../../../images/trustpilotstar.png'
import sourceforcestar from '../../../images/sourceforcestar.png'
import livechatstar from '../../../images/livechatstar.png'
import capterrastar from '../../../images/capterrastar.png'
import getappstar from '../../../images/getappstar.png'
import softwareadvicestar from '../../../images/softwareadvicestar.png'
import { LazyLoadImage } from 'react-lazy-load-image-component'

import Integrations from './integrations'

export default function Content() {

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    };


    return (
        <>
            <div class="container d-flex flex-wrap py-5 bg-white">
                <div class="col-md-12 text-center">
                    <h1 class="text-black  mb-3 text-center fw-bold">
                        Review of Self Storage Software by our Clients
                    </h1>
                </div>
                <div className="row row-cols-2 row-cols-sm-3 row-cols-md-3">
                <div class="col-lg col-md-4 my-4 my-md-2">
                    <div class="p-3 shadow-sm shadow d-flex justify-content-center">
                    <LazyLoadImage className="w-75" effect="blur" src={getappstar} alt="member" />
                    </div>
                </div>
                <div class="col-lg col-md-4 my-4 my-md-2">
                    <div class="p-3 shadow-sm shadow d-flex justify-content-center">
                    <LazyLoadImage className="w-75" effect="blur" src={capterrastar} alt="member" />
                    </div>
                </div>
                <div class="col-lg col-md-4 my-4 my-md-2">
                    <div class="p-3 shadow-sm shadow d-flex justify-content-center">
                    <LazyLoadImage className="w-75" effect="blur" src={softwareadvicestar} alt="member" />
                    </div>
                </div>
                <div class="col-lg col-md-4 my-4 my-md-2">
                    <div class="p-3 shadow-sm shadow d-flex justify-content-center">
                    <LazyLoadImage className="w-75" effect="blur" src={livechatstar} alt="member" />
                    </div>
                </div>
                <div class="col-lg col-md-4 my-4 my-md-2">
                    <div class="p-3 shadow-sm shadow d-flex justify-content-center">
                        <LazyLoadImage effect="blur" src={sourceforcestar} className="w-75" alt="member" />
                    </div>
                </div>
                <div class="col-lg col-md-4 my-4 my-md-2">
                    <div class="p-3 shadow-sm shadow d-flex justify-content-center">
                        <LazyLoadImage effect="blur" src={trustpilotstar} className="w-75" alt="member" />
                    </div>
                </div>
                </div>
            </div>

            <div className="container-fluid bg-light pt-0">
                <div className="container py-3 ">
                    <div className="row my-4">
                        <div className="col-lg-4 px-1 mb-3">
                            <div className="card br-20 h-100 text-center background-grey">
                                <div className="card-body px-3 py-4">
                                    <div className="py-4">
                                        <span className="border px-3 py-4  border-dark rounded-pill">
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="40"
                                                height="40"
                                                viewBox="0 0 24 24">
                                                <path fill="#2d5cd0" d="M16 13h-2a2 2 0 11-4 0H8a4 4 0 008 0zM10 10a1 1 0 11-2 0 1 1 0 012 0zM15 11a1 1 0 100-2 1 1 0 000 2z"></path>
                                                <path
                                                    fillRule="evenodd" fill="#2d5cd0"
                                                    d="M22 12c0 5.523-4.477 10-10 10S2 17.523 2 12 6.477 2 12 2s10 4.477 10 10zm-2 0a8 8 0 11-16 0 8 8 0 0116 0z"
                                                    clipRule="evenodd"
                                                ></path>
                                            </svg>
                                        </span>
                                    </div>
                                    <p className="fw-bold mt-2 mb-4">Efficient</p>
                                    <p className="">Make the move in process easy for your tenant with just 6 clicks on the tenant portal.</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 px-1 mb-3">
                            <div className="card br-20 h-100 text-center text-white bg-blue">
                                <div className="card-body px-3 py-4">
                                    <div className="py-4">
                                        <span className="border px-3 py-4  border-white rounded-pill">
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="40"
                                                height="40"
                                                viewBox="0 0 512 512">
                                                <path fill="#ffff" d="M396.8 352h22.4c6.4 0 12.8-6.4 12.8-12.8V108.8c0-6.4-6.4-12.8-12.8-12.8h-22.4c-6.4 0-12.8 6.4-12.8 12.8v230.4c0 6.4 6.4 12.8 12.8 12.8zm-192 0h22.4c6.4 0 12.8-6.4 12.8-12.8V140.8c0-6.4-6.4-12.8-12.8-12.8h-22.4c-6.4 0-12.8 6.4-12.8 12.8v198.4c0 6.4 6.4 12.8 12.8 12.8zm96 0h22.4c6.4 0 12.8-6.4 12.8-12.8V204.8c0-6.4-6.4-12.8-12.8-12.8h-22.4c-6.4 0-12.8 6.4-12.8 12.8v134.4c0 6.4 6.4 12.8 12.8 12.8zM496 400H48V80c0-8.84-7.16-16-16-16H16C7.16 64 0 71.16 0 80v336c0 17.67 14.33 32 32 32h464c8.84 0 16-7.16 16-16v-16c0-8.84-7.16-16-16-16zm-387.2-48h22.4c6.4 0 12.8-6.4 12.8-12.8v-70.4c0-6.4-6.4-12.8-12.8-12.8h-22.4c-6.4 0-12.8 6.4-12.8 12.8v70.4c0 6.4 6.4 12.8 12.8 12.8z"></path>
                                            </svg>
                                        </span>
                                    </div>
                                    <p className="fw-bold mt-2 mb-4">Centralised</p>
                                    <p className="">Know the status of all units on your facility at one glance and perform lease operations.</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 px-1 mb-3">
                            <div className="card br-20 h-100 text-center background-grey">
                                <div className="card-body px-3 py-4">
                                    <div className="py-4">
                                        <span className="border px-3 py-4  border-dark rounded-pill">
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="40"
                                                height="40"
                                                viewBox="0 0 24 24"
                                            >
                                                <path fill="#2d5cd0"
                                                    fillRule="evenodd"
                                                    d="M9 5H5v4h4V5zM3 3v8h8V3H3zM19 5h-4v4h4V5zm-6-2v8h8V3h-8zM9 15H5v4h4v-4zm-6-2v8h8v-8H3z"
                                                    clipRule="evenodd"
                                                ></path>
                                                <path fill="#2d5cd0" d="M13 13h2v8h-2v-8zM16 13h2v8h-2v-8zM19 13h2v8h-2v-8z"></path>
                                            </svg>
                                        </span>
                                    </div>
                                    <p className="fw-bold mt-2 mb-4">Powerful</p>
                                    <p className="">Get powerful integrations for accounting, online payments, and gate access systems.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container mt-4">
                <h1 className="text-center fw-bold">Streamlined Self Storage Management</h1>
                <h6 className="text-center fs-5 my-3">Use 6Storage for renting units online, managing lease activity, and getting payments on time.</h6>
                <div className="row row-cols-1 row-cols-sm-2 row-cols-md-3 my-5">
                    <div className="col my-3">
                        <div className="d-flex">
                            <div>
                                <span className="rounded-pill p-2 bg-blue">
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="25"
                                        height="22"
                                        viewBox="0 0 512 512"
                                    >
                                        <path fill="#fff" d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z"></path>
                                    </svg>
                                </span></div> <p className="fw-bold d-inline ms-3">Automated Invoices and Payments</p>
                        </div>
                        <p className="mt-1 text-center fw-light">
                            Get notified on auto invoices and manage multiple credit cards to enable auto payments.
                        </p>
                    </div>
                    <div className="col my-3">
                        <div className="d-flex">
                            <div>
                                <span className="rounded-pill p-2 bg-blue">
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="25"
                                        height="22"
                                        viewBox="0 0 512 512"
                                    >
                                        <path fill="#fff" d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z"></path>
                                    </svg>
                                </span></div> <p className="fw-bold d-inline ms-3">Multiple Facilities Management</p>
                        </div>
                        <p className="mt-1 text-center fw-light">
                            Centralize all facility location details within one account, saying goodby to regional management.
                        </p>
                    </div>
                    <div className="col my-3">
                        <div className="d-flex">
                            <div>
                                <span className="rounded-pill p-2 bg-blue">
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="25"
                                        height="22"
                                        viewBox="0 0 512 512"
                                    >
                                        <path fill="#fff" d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z"></path>
                                    </svg>
                                </span></div> <p className="fw-bold d-inline ms-3">Lead Management and Reservation</p>
                        </div>
                        <p className="mt-1 text-center fw-light">
                            Know where your leads come from, see analytics of lead data, and follow-up in a timely manner.
                        </p>
                    </div>
                    <div className="col my-3">
                        <div className="d-flex">
                            <div>
                                <span className="rounded-pill p-2 bg-blue">
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="25"
                                        height="22"
                                        viewBox="0 0 512 512"
                                    >
                                        <path fill="#fff" d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z"></path>
                                    </svg>
                                </span></div> <p className="fw-bold d-inline ms-3">Interactive and Intuitive Dashboard</p>
                        </div>
                        <p className="mt-1 text-center fw-light">
                            See your facility status on one dashboard - payments made, overdue tenants, and more!
                        </p>
                    </div>
                    <div className="col my-3">
                        <div className="d-flex">
                            <div>
                                <span className="rounded-pill p-2 bg-blue">
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="25"
                                        height="22"
                                        viewBox="0 0 512 512"
                                    >
                                        <path fill="#fff" d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z"></path>
                                    </svg>
                                </span></div> <p className="fw-bold d-inline ms-3">Native Language Customization</p>
                        </div>
                        <p className="mt-1 text-center fw-light">
                            Set 6Storage to your native language, ensuring the best experience for your tenants.
                        </p>
                    </div>
                    <div className=" my-3">
                        <div className="d-flex">
                            <div>
                                <span className="rounded-pill p-2 bg-blue">
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="25"
                                        height="22"
                                        viewBox="0 0 512 512"
                                    >
                                        <path fill="#fff" d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z"></path>
                                    </svg>
                                </span></div> <p className="fw-bold d-inline ms-3">Move-In / Move-Out Wizard</p>
                        </div>
                        <p className="mt-1 text-center fw-light">
                            Check the status of all units on your facility and  perform lease operations in a click.
                        </p>
                    </div>
                </div>
            </div>

            <div className="container mt-4 mb-5">
                <h2 className="text-center fw-bold mb-4">Why Choose 6Storage?</h2>
                <div id="carouselExampleIndicators" class="carousel slide" data-bs-ride="carousel">
                    <div class="carousel-indicators">
                        <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="0" class="active" aria-current="true" aria-label="Slide 1"></button>
                        <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="1" aria-label="Slide 2"></button>
                        <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="2" aria-label="Slide 3"></button>
                    </div>
                    <div class="carousel-inner text-center">
                        <div class="carousel-item active">
                            <div class="row">
                                <div class="col-lg-6">
                                    <div class="card">
                                        <div class="card-body">
                                            <p className="lh-lg text-dark font-size-0.9rem">
                                                “Great software and support system! Having used a different system when we first opened the facility, when we found 6Storage on Google...the difference in software was like day and night. Any suggestions made on a support ticket will be incorporated to make your software an individual experience. Nothing is too much trouble for the support team.
                                                 Extremely happy with this software. Can highly recommend it to others in the self-storage business.”
                                            </p>
                                            <h5 className="fw-bold mt-3">Gail B.</h5>
                                            <h5 className="fw-bold">Company Administrator</h5>
                                            <div className="my-4">
                                                <LazyLoadImage className='w-50 h-50' effect="blur" src={captera} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-6">
                                    <div class="card">
                                        <div class="card-body">
                                            <p className="lh-lg text-dark font-size-0.9rem">
                                                “By using 6Storage, I now have an up to date view of everything going on in the facility and where all my tenants are. It’s easy to manage payments and keep track of debt levels. 
                                                Automatic invoicing and late fee application are the highlights. The ability to email/SMS clients from within the software makes debt management more efficient. It has streamlined all processes within the business. I like that it is a cloud-based solution allowing me access from anywhere.”</p>
                                            <h5 className="fw-bold mt-3">Chris O.</h5>
                                            <h5 className="fw-bold">Facility Manager</h5>
                                            <div className="my-4">
                                            <LazyLoadImage className='w-50 h-50' effect="blur" src={captera} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="carousel-item">
                            <div class="row">
                                <div class="col-lg-6 d-flex align-items-stretch align-content-space-between">
                                    <div class="card">
                                        <div class="card-body d-flex flex-column justify-content-between">
                                            <p className="lh-lg text-dark font-size-0.9rem">
                                                “This system works really well and simply for what we need to run our storage business. If we have any problems or issues or even things we think could work better or we need to work differently for us then the customer support team are always on hand and happy to help us work through things and they try their best to get these things resolved and create the things that aren't in place yet.”</p>
                                            <div>
                                                <h5 className="fw-bold mt-3">Paula H.</h5>
                                                <h5 className="fw-bold">Customer Services Manager</h5>
                                                <div className="my-4">
                                                <LazyLoadImage effect="blur" src={sourceforce} className="w-100 h-100" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-6 d-flex align-items-stretch align-content-space-between">
                                    <div class="card">
                                        <div class="card-body d-flex flex-column justify-content-between">
                                            <p className="lh-lg text-dark font-size-0.9rem">
                                                “The continued support provided by 6storage has been exemplary. This has made the transition to V2 a very easy and welcomed addition to our business.”</p>
                                            <div>
                                                <h5 className="fw-bold mt-3">Jordan.</h5>
                                                <h5 className="fw-bold">White Owner </h5>
                                                <div className="my-4">
                                                <LazyLoadImage effect="blur" src={trustpilot} className="w-100 h-100" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="carousel-item">
                            <div class="row">
                                <div class="col-lg-6 d-flex align-items-stretch align-content-space-between">
                                    <div class="card">
                                        <div class="card-body d-flex flex-column justify-content-between">
                                            <p className="lh-lg text-dark font-size-0.9rem">
                                                “Everything from online booking, to automatic invoices. The management reports are easy to understand and to react to. The system takes care of everything, allowing you more time to concentrate on the day-to-day business.”</p>
                                            <div>
                                                <h5 className="fw-bold mt-3">Bryn C.</h5>
                                                <h5 className="fw-bold">Manager</h5>
                                                <div className="my-4">
                                                <LazyLoadImage effect="blur" src={g2} className="w-100 h-100" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-6 d-flex align-items-stretch align-content-space-between">
                                    <div class="card">
                                        <div class="card-body d-flex flex-column justify-content-between">
                                            <p className="lh-lg text-dark font-size-0.9rem">
                                                “Easy to use software, runs itself. The customer portal works great and auto payments flow well. Tech help is available via webchat if needed and is very fast to respond. Works well on Mac & Windows. Very pleased to have found this company.”</p>
                                            <div>
                                                <h5 className="fw-bold mt-3">John B</h5>
                                                <h5 className="fw-bold">Manager </h5>
                                                <div className="my-4">
                                                <LazyLoadImage effect="blur" src={getapp} className="w-100 h-100" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="prev">
                        <span class="carousel-control-prev-icon bg-secondary rounded-circle" aria-hidden="true"></span>
                        <span class="visually-hidden">Previous</span>
                    </button>
                    <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="next">
                        <span class="carousel-control-next-icon bg-secondary rounded-circle" aria-hidden="true"></span>
                        <span class="visually-hidden">Next</span>
                    </button>
                </div>
            </div>
            {/* <div className="container mt-4">
                <h2 className="text-center fw-bold mb-4">Why Choose 6Storage?</h2>
                    <div className="col-lg-6 text-center px-3"></div>
            <Integrations/>
                </div> */}
                <div style={{ background: '#0f69ba' }}>
            <div className="container">
                <h2 className="text-center text-white px-2 pt-5 fw-bold mt-2 mb-3">The Smart Way to Run a Storage Facility and Retain Your Customers</h2>
                <h3 className="text-center text-white px-2">Curated by a self-storage owner, used by global storage operators of 40+ countries.</h3>
                <div className="mt-4 pb-5 text-center" >
                    <button onClick={scrollToTop} className="btn text-white px-4 br-20 bg-danger">Get a Free Demo</button>
                </div>
            </div>
            </div>
        </>
    )
}