import member from '../../images/footer1.png';
import fedesa from '../../images/footer2.png';
import ssa from '../../images/footer3.png';
import { LazyLoadImage } from 'react-lazy-load-image-component';
export default function Footer() {
    return (
        <>
            <div className="container-fluid py-5" style={{ background: 'rgba(48, 4, 234, 0.4)' }}>
                <div className="container px-0 px-lg-5">
                    <div className="row px-2 px-lg-5">
                        <div className="col-md-4 ">
                            <h6 className="fw-bold">Kontakt:</h6>
                            <p className="mb-1">Raiffeisenstraße 27 61191 Rosbach vor </p>
                            <p className="mb-1">der Höhe Germany</p>
                            <p className="mb-1">+1(844)334-4666</p>
                            <p className="mb-1">+49 60 0324 93898</p>
                            <p className="mb-1">support@6storage.com</p>
                        </div>
                        <div className="col-md-4 ">
                            <h6 className="fw-bold">Rechtliches:</h6>
                            <p className="mb-1">Datenschutzrichtlinie </p>
                            <p className="mb-1">Nutzungsbedingungen</p>

                        </div>
                        <div className="col-md-4 ">
                            <h6 className="fw-bold">Unsere Selfstorage-Vereine</h6>
                            <div className="row">
                                <div className="col-4 d-flex justify-content-center align-items-center">
                                    <LazyLoadImage effect="blur" src={member} className="w-75" alt="member" />
                                </div>
                                <div className="col-4 d-flex justify-content-center align-items-center">
                                    <LazyLoadImage effect="blur" src={fedesa} className="w-100" alt="fedesa" />
                                </div>
                                <div className="col-4 d-flex justify-content-center align-items-center">
                                    <LazyLoadImage effect="blur" src={ssa} className="w-100" alt="ssa" />
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}