import '../page.css';
import axios from "axios";
import Content from '../ssaa/components/content'
import Footer from '../ssaa/components/footer';
import Form from '../ssaa/components/form';
import Header from '../ssaa/components/header';
import React, { useEffect } from 'react';
import FormData, { getHeaders } from 'form-data';



function QRPAGE() {

    const data= 'qrpage';

    useEffect(() => {
        document.title = '6Storage|storage management software';
        document.getElementsByTagName("META")[3].content = "Automate Your Day-to-Day Storage Management Tasks. Trusted by 1000s Of Facilities. Manage Your Facility Remotely. 24/7 Technical Support. 5 Star Rated Self Storage Software.";
    });
    return (
        <>
            <Header />
            <div className='px-0 h-100  container-fluid'>
                <Form data = {data}/>
                <Content />
                <Footer />
            </div>
        </>
    );
}

export default QRPAGE;
