import logo from '../../../images/logo.png';
import { LazyLoadImage } from 'react-lazy-load-image-component';

export default function Header() {
    return (
        <>
            <nav className="navbar bg-light ">
                <div className="container-fluid justify-content-center">
                    <a className="navbar-brand text-center">
                        <LazyLoadImage className='w-50 h-50' effect="blur" src={logo} />
                    </a>
                </div>
            </nav>
        </>
    )
}