import { initializeApp } from "firebase/app"
import { getFirestore, collection, addDoc, doc, setDoc, getDoc } from "firebase/firestore"
import {getAuth,signInWithEmailAndPassword,signOut, RecaptchaVerifier, signInWithPhoneNumber } from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyDtnuiz8USSKLcuVJEJgtGEV4DSgde1c3o",
  authDomain: "landing-site-73a61.firebaseapp.com",
  projectId: "landing-site-73a61",
  storageBucket: "landing-site-73a61.appspot.com",
  messagingSenderId: "396389874365",
  appId: "1:396389874365:web:2b41cbfc78913bd06c95d4",
  measurementId: "G-MJ7YK37FC7"
};


const app = initializeApp(firebaseConfig)
  const db = getFirestore(app)
  const auth = getAuth(app);


  const logInWithEmailAndPassword = async (email, password) => {
    try {
      await signInWithEmailAndPassword(auth, email, password);
    } catch (err) {
      console.error(err);
      alert(err.message);
    }
  };

  const logout = () => {
    signOut(auth);
  };
  
  
  export {db, auth, logInWithEmailAndPassword, logout, RecaptchaVerifier, signInWithPhoneNumber, collection, addDoc, doc, setDoc, getDoc}