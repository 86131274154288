import logo from '../../images/logo.png';
import { LazyLoadImage } from 'react-lazy-load-image-component';
export default function Header(){
    return(
        <>
        <nav className="navbar bg-light ">
            <div className="container-fluid">
                <a className="navbar-brand"><LazyLoadImage effect="blur" className='w-50 h-50' src={logo}/></a>
            </div>
         </nav>  
        </>
    )
}