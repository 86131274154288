import './home.css'
import { collection, query, orderBy, onSnapshot, where } from "firebase/firestore"
import { useEffect, useState } from "react";
import { db } from '../../config/firebase';


export default function ExpandTable(props) {

    const [userinfo, setUserInfo] = useState([])

    useEffect(() => {
        const taskColRef = query(collection(db, 'Leads'), where('number', '==',  props.number) , orderBy('create_at', 'desc'))
        onSnapshot(taskColRef, (snapshot) => {
            setUserInfo(snapshot.docs.map(doc => ({
                id: doc.id,
                data: doc.data()
            })))
        })
    }, [console.log(userinfo)]);

    return (<>
        <tr class="collapse row-child" id={'cc'+props.id}>
            <td colspan="999">
                <div>
                    <table class="table table-hover table-borderless text-center mb-0">
                        <thead>
                            <tr>
                                {/* <th>Date</th> */}
                                <th>Name</th>
                                <th>Email</th>
                                <th>Company</th>
                                <th>Campaign</th>
                                <th>Medium</th>
                                <th>Source</th>
                                <th>Categories</th>
                                <th>OTP Verification</th>
                            </tr>
                        </thead>
                        <tbody style={{ fontSize: '12px' }}>
                        {userinfo.map((task) => (
                            <>
                            <tr>
                                {/* <td>{task.data.date}</td> */}
                                <td>{task.data.name}</td>
                                <td>{task.data.email}</td>
                                <td>{task.data.company}</td>
                                <td>{task.data.utm_campaign}</td>
                                <td>{task.data.utm_medium}</td>
                                <td>{task.data.utm_source}</td>
                                <td>{task.data.categories}</td>
                                {task.data.otp == true? 
                                <td>Verified</td>:<td>Not Verified</td>}
                            </tr>
                            </>
                        ))}
                        </tbody>
                    </table>
                </div>
            </td>
        </tr>
    </>);
}