import './home.css'
import 'font-awesome/css/font-awesome.min.css';
import { Fragment, useState } from 'react';
import { data } from './data';
import Readonly from './readonly';
import Editrow from './editrow';

export default function Leadtable() {
  const [tableData, setTableData] = useState(data);
  const [toggle, setToggle] = useState(null);
  const [search, setSearch] = useState("");
  const [editData, setEditData] = useState({
    january: "",
    february: "",
    march: "",
    april: "",
    may: "",
    june: "",
    july: "",
    august: "",
    september: "",
    october: "",
    november: "",
    december: "",
    total: "",
  });

  const Change = (e) => {
    const { name, value } = e.target;
    setNewData({ ...newData, [name]: value });
  };

  const Add = (e) => {
    e.preventDefault();
    setTableData([...tableData, { ...newData, id: Math.random() }]);
    setNewData({
      january: "",
      february: "",
      march: "",
      april: "",
      may: "",
      june: "",
      july: "",
      august: "",
      september: "",
      october: "",
      november: "",
      december: "",
      total: "",
    });
  };

  const Delete = (del) => {
    const delData = tableData.filter((tbd) => {
      return del !== tbd.id;
    });
    setTableData(delData);
  };

  const Edit = (data) => {
    console.log (data);
    setToggle(data);
    setEditData(data);
  };

 
  return (
    <>
      {/* <form>
        <table class="table table-hover">
          <thead className='text-center'>
            <tr>
              <th id="tr" onClick={() => Sort("id")}>

              </th>
              <th id="tr" onClick={() => Sort("january")}>
                January
              </th>
              <th id="tr" onClick={() => Sort("february")}>
                February
              </th>
              <th id="tr" onClick={() => Sort("march")}>
                March
              </th>
              <th id="tr" onClick={() => Sort("april")}>
                April
              </th>
              <th id="tr" onClick={() => Sort("may")}>
                May
              </th>
              <th id="tr" onClick={() => Sort("june")}>
                June
              </th>
              <th id="tr" onClick={() => Sort("july")}>
                July
              </th>
              <th id="tr" onClick={() => Sort("august")}>
                August
              </th>
              <th id="tr" onClick={() => Sort("september")}>
                September
              </th>
              <th id="tr" onClick={() => Sort("october")}>
                October
              </th>
              <th id="tr" onClick={() => Sort("november")}>
                November
              </th>
              <th id="tr" onClick={() => Sort("december")}>
                December
              </th>
              <th id="tr" onClick={() => Sort("total")}>
                Total
              </th>
              <th >Action</th>
            </tr>
          </thead>
          <tbody className='text-center'>
            {tableData.map((data) => {
              return (
                <>
               
                <Fragment>
                {toggle === data ? (
                  <Editrow 
                  tableData={tableData}
                  setTableData={setTableData}
                  setEditData={setEditData}
                  editData={editData}
                  />
                ) : (
                  <Readonly data={data} Edit={Edit} Delete={Delete}/>
                )} 
                </Fragment>
                </>
              );
            })}
          </tbody>
        </table>
      </form> */}
      <table class="table table-hover">
                <thead className='text-center'>
                    <tr>
                        <th scope="col"></th>
                        <th scope="col">January</th>
                        <th scope="col">February</th>
                        <th scope="col">March</th>
                        <th scope="col">April</th>
                        <th scope="col">May</th>
                        <th scope="col">June</th>
                        <th scope="col">July</th>
                        <th scope="col">August</th>
                        <th scope="col">September</th>
                        <th scope="col">October</th>
                        <th scope="col">November</th>
                        <th scope="col">December</th>
                        <th scope="col">Total</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <th scope="row">2021</th>
                        <td className='text-center'>59</td>
                        <td className='text-center'>61</td>
                        <td className='text-center'>63</td>
                        <td className='text-center'>56</td>
                        <td className='text-center'>51</td>
                        <td className='text-center'>34</td>
                        <td className='text-center'>37</td>
                        <td className='text-center'>33</td>
                        <td className='text-center'>53</td>
                        <td className='text-center'>53</td>
                        <td className='text-center'>45</td>
                        <td className='text-center'>60</td>
                        <td className='text-center'>605</td>
                    </tr>
                    <tr>
                        <th scope="row">2022</th>
                        <td className='text-center'>82</td>
                        <td className='text-center'>57</td>
                        <td className='text-center'>76</td>
                        <td className='text-center'>66</td>
                        <td className='text-center'>55</td>
                        <td className='text-center'>41</td>
                        <td className='text-center'>36</td>
                        <td className='text-center'>30</td>
                        <td className='text-center'>24</td>
                        <td className='text-center'>09</td>
                        <td className='text-center'>15</td>
                        <td className='text-center'>19</td>
                        <td className='text-center'>510</td>
                    </tr>
                    <tr>
                        <th scope="row">2023</th>
                        <td className='text-center'>64</td>
                        <td className='text-center'>48</td>
                        <td className='text-center text-primary' contenteditable='true'>24</td>
                        <td className='text-center'></td>
                        <td className='text-center'></td>
                        <td className='text-center'></td>
                        <td className='text-center'></td>
                        <td className='text-center'></td>
                        <td className='text-center'></td>
                        <td className='text-center'></td>
                        <td className='text-center'></td>
                        <td className='text-center'></td>
                        <td className='text-center text-primary' contenteditable='true'>136</td>
                    </tr>
                </tbody>
            </table>
    </>
  )
}
