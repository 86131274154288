import './home.css'
import { collection, query, orderBy, onSnapshot, where } from "firebase/firestore"
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { db } from '../../config/firebase';
import { DateRangePicker } from 'react-date-range';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';

import ExpandTable from './expandtable';
import Leadtable from './leadtable';

export default function Home() {
    const navigate = useNavigate();
    const [tasks, setTasks] = useState([])
    const checkLogin = localStorage.getItem('check');
    const [startDate, setStartDate] = useState();
    const [endDate, setEndDate] = useState();

    useEffect(() => {
        document.title = 'Admin Page';
        if (checkLogin === 'false') {
            navigate('/');
        }
        callForData();
    }, [startDate,endDate]);

    const callForData = () => {
        if (startDate !== undefined && endDate !== undefined) {
            console.log('Working on 1', startDate, endDate)
            const taskColRef = query(collection(db, 'Users'), where('create_at', ">=", startDate), where('create_at', "<=", endDate), orderBy('create_at', 'desc'))
            onSnapshot(taskColRef, (snapshot) => {
                setTasks(snapshot.docs.map(doc => ({
                    id: doc.id,
                    data: doc.data()
                })))
            })
        } else if (endDate !== undefined) {
            console.log('Working on 2', startDate, endDate)
            const taskColRef = query(collection(db, 'Users'), where('create_at', "<=", endDate), orderBy('create_at', 'desc'))
            onSnapshot(taskColRef, (snapshot) => {
                setTasks(snapshot.docs.map(doc => ({
                    id: doc.id,
                    data: doc.data()
                })))
            })
        } else {
            console.log('Working on 3', startDate, endDate)
            const taskColRef = query(collection(db, 'Users'), orderBy('create_at', 'desc'))
            onSnapshot(taskColRef, (snapshot) => {
                setTasks(snapshot.docs.map(doc => ({
                    id: doc.id,
                    data: doc.data()
                })))
            })
        }

    }

    const handleSelect = (date) => {
        console.log('Start Date', date.selection.startDate);
        setStartDate(Date.parse(date.selection.startDate));
        setEndDate(Date.parse(date.selection.endDate));
        callForData();
    };

    const selectionRange = {
        startDate: startDate,
        endDate: endDate,
        key: 'selection',
    }

   

    return (
        <>
            <div className='body'>
                <h2 class="text-center text-primary p-3 fs-3 fw-bolder">Monthly Lead Trends</h2>
                <div className='container bg-white'>
                    <div class="table-responsive">
                        <Leadtable />
                    </div>
                </div>
                <div className="text-center text-primary py-3 fw-bold fs-5">
                    Marketing
                </div>

                <div class="container bg-white radius">
                    <div class="d-flex justify-content-center row">
                        <div class="rounded">
                            <div class="table-responsive">
                                {/* <div>
                                <div class="d-flex justify-content-end p-3">
                                    <button class="btn btn-primary" type="button" data-bs-toggle="collapse" data-bs-target="#collapseExample" aria-expanded="false" aria-controls="collapseExample">
                                    <i class="fa fa-filter"></i> Filter
                                    </button>
                                </div>
                                <div class="collapse" id="collapseExample">
                                    <div class="card card-body">
                                        <DateRangePicker
                                    ranges={[selectionRange]}
                                    onChange={handleSelect}
                                />
                                    </div>
                                </div>
                                </div> */}
                                <table class="table table-responsive table-hover">
                                    <thead>
                                        <tr className='text-left'>
                                            <th>Date</th>
                                            <th>Name</th>
                                            <th>Mobile Number</th>
                                            <th>Email</th>
                                            <th>Company</th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {tasks.map((task) => (
                                            <><tr class="cell-1" data-toggle="collapse" data-target={'#cc' + task.data.create_at}>
                                                <td>{task.data.date}</td>
                                                <td>{task.data.name}</td>
                                                <td>{task.data.number}</td>
                                                <td>{task.data.email}</td>
                                                <td>{task.data.company}</td>
                                                <td data-toggle="collapse" data-target={'#cc' + task.data.create_at}>
                                                    <button type="button" class="btn btn-primary btn-sm" data-bs-toggle="collapse" data-bs-target={'#cc' + task.data.create_at} aria-expanded="false" aria-controls={task.data.create_at}>
                                                        Details
                                                    </button>
                                                </td>

                                            </tr><ExpandTable id={task.data.create_at} number={task.data.number} /></>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )

}