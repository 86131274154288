import location from '../../../images/location.png';
import call from '../../../images/call.png';
import mail from '../../../images/mail.png';
import SSAA from '../../../images/footer3.png';
import TSSA from '../../../images/TSSA.png';
import SSA from '../../../images/SSA.png';
import fedessa from '../../../images/fedessa.png';
import KSSOA from '../../../images/KSSOA.png';
import MSSOA from '../../../images/MSSOA.png';
import { LazyLoadImage } from 'react-lazy-load-image-component';

export default function Footer() {
    return (
        <>
            <div className="container-fluid py-5" style={{ background: 'rgb(241 245 249)' }}>
                <div className="container px-lg-5">
                    <div className="row px-2 px-lg-5">
                        <div className="col-md-6 py-3 px-4">
                            <h6 className="fw-bold text-left pb-3">Contact us:</h6>
                            <ul class="list-unstyled">
                                <li>
                                    <div class="d-flex mb-lg-1 mr-lg-3">
                                        <LazyLoadImage effect="blur" className='logo pr-4' src={location} alt='location' />
                                        <div class="media-body text-sm-start">
                                            <a href="https://goo.gl/maps/MNByUkJcZnreaQ7m7" target="_blank" class="text-sm-start text-decoration-none text-black">
                                                <span className='ps-2'>4888 New Baumgartner Road, Suite C, St. Louis Missouri <span className='ps-2'>63129, USA</span></span>
                                            </a>
                                        </div>
                                    </div>
                                </li>
                                <li class="pb-0">
                                    <div class="d-flex">
                                        <LazyLoadImage effect="blur" className='logo pr-4' src={call} alt='call' />
                                        <div class="media-body">

                                            <a href="tel:+1 (844) 334-4666" className='text-decoration-none text-black ps-2'>+1 314-786-3309</a>
                                        </div>
                                    </div>
                                </li>
                                <li class="pb-0">
                                    <div class="d-flex">
                                        <LazyLoadImage effect="blur" className='logo pr-4' src={mail} alt='mail' />
                                        <div class="media-body">
                                            <a href="mailto:support@6storage.com" className='text-decoration-none text-black ps-2'>help@6storage.com</a>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </div>

                        <div className="col-md-6 py-3 text-center">
                            <h6 className="fw-bold">Our Self-Storage Associations</h6>
                            <div className="row">
                                <div class="row w-100">
                                    <div class="col-4 d-flex justify-content-end">
                                        <LazyLoadImage effect="blur" class="img-fluid w-50" alt="TSSA" src={TSSA} />
                                    </div>
                                    <div class="col-4 d-flex justify-content-center">
                                        <LazyLoadImage effect="blur" class="img-fluid w-50" alt="SSA" src={SSA} />
                                    </div>
                                    <div class="col-4 d-flex justify-content-start">
                                        <LazyLoadImage effect="blur" class="img-fluid w-75" alt="SSAA" src={SSAA} />
                                    </div>
                                </div>
                                <div class="row w-100 pt-2">
                                    <div class="col-4 d-flex justify-content-end">
                                        <LazyLoadImage effect="blur" class="img-fluid w-75" alt="Fedessa" src={fedessa} />
                                    </div>
                                    <div class="col-4 d-flex justify-content-center">
                                        <LazyLoadImage effect="blur" class="img-fluid w-50" alt="KSSOA" src={KSSOA} />
                                    </div>
                                    <div class="col-4 d-flex justify-content-start">
                                        <LazyLoadImage effect="blur" class="img-fluid w-50" alt="MSSOA" src={MSSOA} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="bg-black">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-6 text-left my-1">
                            <p class="my-3 text-light">
                                © Copyright 6Storage
                                2023. All Rights Reserved.
                            </p>
                        </div>
                        <div class="col-lg-6 text-center">
                            <ul className='d-flex list-unstyled justify-content-center'>
                                <li class="float-left pr-3 mt-3">
                                    <a href="https://www.6storage.com/privacypolicy/" class="text-white m-3 pt-4 text-decoration-none">Privacy Policy</a>
                                </li>
                                <li class="float-left mt-3">
                                    <a href="https://www.6storage.com/termsofservice/" class="text-white m-3 text-decoration-none">Terms of Use</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}