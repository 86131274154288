import { BrowserRouter, Routes, Route } from "react-router-dom";
import { useEffect, useState } from "react";
import Login from "./admin/login/login"
import Home from "./admin/home/home"
import Page from "./page/index"
import SSAA from "./page/ssaa/index"
import QRPAGE from './page/qr/index'

function App() {

  useEffect(() => {
  }, [localStorage.setItem('check', 'false')]);


  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<SSAA />} />
        <Route path="/qr" element={<QRPAGE />} />
        <Route path="/de" element={<Page />} />
        <Route path="/admin/" element={<Login />} />
        <Route path="/admin/home/" element={<Home />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
