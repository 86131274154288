import './page.css';
import Content from './components/content';
import Footer from './components/footer';
import Form from './components/form';
import Header from './components/header';
import React, { useEffect } from 'react';

function Page() {
    useEffect(() => {
        // refreshToken();
        document.title = '6Storage participating in SELF STORAGE EXPO ASIA 2022';
        document.getElementsByTagName("META")[3].content = "We are excited to announce that 6Storage will be at the SELF STORAGE EXPO ASIA 2022 from 14th to 16th November.  Drop by booth for a quick demo of the newest software tech for managing yourself-storage business.";
    });
    return (
        <>
            <Header />
            <div className='px-0 h-100  container-fluid'>
                <Form />
                <Content />
                <Footer />
            </div>
        </>
    );
}

export default Page;