import '../page.css';
import axios from "axios";
import Content from './components/content'
import Footer from './components/footer';
import Form from './components/form';
import Header from './components/header';
import React, { useEffect } from 'react';
import FormData, { getHeaders } from 'form-data';



function SSAA() {

    const data= 'ssaa';

    // var config = {
    //     method: 'post',
    //     mode: "cors",
    //     url: 'https://accounts.zoho.com/oauth/v2/token',
    //     headers: {
    //         "Access-Control-Allow-Origin" : "*",
    //         // 'Cookie': 'stk=097886f3d845ae7ef5170ef3e3126a74; JSESSIONID=A4C4158814B3F483F11DD1E7DC6C8942; _zcsr_tmp=f7250507-3045-4d9d-9b17-90ab21f64c08; b266a5bf57=a711b6da0e6cbadb5e254290f114a026; e188bc05fe=4d6e62173a764ac5410d1192f41034cd; iamcsr=f7250507-3045-4d9d-9b17-90ab21f64c08',
    //         'client_id': '1000.O92N41MTGT253IKNOUGJYHIVM4LE8X',
    //         'client_secret': '3bb30fc5c1ce61794dc2ef84afc24ab94dc2e6bc1e',
    //         'refresh_token': '1000.cbc19bc55db8e20d4105386ba75d81db.07929bda8a9dd803bc27c68f3ba4e3c1',
    //         'grant_type': 'refresh_token'

    //     },
    // };

    var config = {
        method: 'get',
        url: 'https://www.zohoapis.com/crm/v2.1/Leads/5544955000000436019',
        headers: { 
          'Authorization': 'Bearer 1000.6ee4a266fb478d3b39d1a3e82c12dac3.a8c0a27ec873adb193ade0f7923a76e0', 
        }
      };

    const refreshToken = async () => {
        // axios.post('https://accounts.zoho.com/oauth/v2/token', {
        //     "client_id": "1000.O92N41MTGT253IKNOUGJYHIVM4LE8X",
        //     "client_secret": "3bb30fc5c1ce61794dc2ef84afc24ab94dc2e6bc1e",
        //     "refresh_token": "1000.cbc19bc55db8e20d4105386ba75d81db.07929bda8a9dd803bc27c68f3ba4e3c1",
        //     "grant_type": "refresh_token"
        // }).then((response) => {
        //     console.log(response.data);
        // }).catch(error => {
        //     console.log(error);
        // });
        axios(config)
            .then(function (response) {
                console.log(JSON.stringify(response.data));
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    useEffect(() => {
        // refreshToken();
        document.title = '6Storage|storage management software';
        document.getElementsByTagName("META")[3].content = "Automate Your Day-to-Day Storage Management Tasks. Trusted by 1000s Of Facilities. Manage Your Facility Remotely. 24/7 Technical Support. 5 Star Rated Self Storage Software.";
    });
    return (
        <>
            <Header />
            <div className='px-0 h-100  container-fluid'>
                <Form data = {data}/>
                <Content />
                <Footer />
            </div>
        </>
    );
}

export default SSAA;
